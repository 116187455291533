export class Config {
    // @ts-ignore
    static AppEnv = process.env.VITE_APP_ENV
  
    static Url() {
      if (this.AppEnv === "dev") return "http://localhost:5173";
      else if (this.AppEnv === "beta")
        return "https://widget.app.deepbot.gozen.io";
      else return "https://widget.app.deepbot.gozen.io";
    }

    static WidgetUrl() {
      if (this.AppEnv === "dev") return "http://localhost:5173/";
      else if (this.AppEnv === "beta") return "https://widget.app.deepbot.gozen.io/";
      else return "https://widget.app.deepbot.gozen.io/";
    }

  }
  