import { Config } from "../config";
import { EVENTS } from "../constants/events";
import {
  __DEEPBOT__IFRAME_STYLE,
  __DEEPBOT__IMAGE__VIEWER,
  __DEEPBOT__LAUNCH__IFRAME_STYLE,
  __DEEPBOT__PROMPT__IFRAME_STYLE,
} from "../constants/index";

const URL = Config.WidgetUrl();
const LAUNCH__URL = `${URL}launch-button/`;
const IMAGE_VIEWER_URL = `${URL}image-viewer/`;
const PROMPT_FRAME_URL = `${URL}prompt/`;

const widgetOrigin = Config.Url();

export class Iframe {
  private static instance: Iframe;

  // @ts-ignore
  private _launcher: HTMLIFrameElement | null = null;

  private widget: HTMLIFrameElement | null = null;

  private _imageViewer: HTMLIFrameElement | null = null;

  private _promptFrame: HTMLIFrameElement | null = null;

  private _promptTimeout: number | null = null;

  constructor() {
    if (Iframe.instance) return Iframe.instance;

    Iframe.instance = this;

    this.createFrames();

    return this;
  }

  protected createFrames = () => {
    const widgetFrame = document.getElementById(
      "deepbotWidget"
    ) as HTMLIFrameElement | null;

    const launcherFrame = document.getElementById(
      "deepbotLaunch"
    ) as HTMLIFrameElement | null;

    const imageViewer = document.getElementById(
      "deepbotImageViewer"
    ) as HTMLIFrameElement | null;

    const promptFrame = document.getElementById(
      "deepbotPrompt"
    ) as HTMLIFrameElement | null;

    this._launcher = launcherFrame
      ? launcherFrame
      : Iframe.createIframe(
          "deepbotLaunch",
          LAUNCH__URL,
          __DEEPBOT__LAUNCH__IFRAME_STYLE
        );

    this.widget = widgetFrame
      ? widgetFrame
      : Iframe.createIframe("deepbotWidget", URL, __DEEPBOT__IFRAME_STYLE);

    this._imageViewer = imageViewer
      ? imageViewer
      : Iframe.createIframe(
          "deepbotImageViewer",
          IMAGE_VIEWER_URL,
          __DEEPBOT__IMAGE__VIEWER
        );

    this._promptFrame =
      promptFrame ??
      Iframe.createIframe(
        "deepbotPrompt",
        PROMPT_FRAME_URL,
        __DEEPBOT__PROMPT__IFRAME_STYLE
      );
  };

  set promptTimeout(value: number) {
    this._promptTimeout = value;
  }

  clearPromptTimeout() {
    if (!this._promptTimeout) return;

    clearTimeout(this._promptTimeout);
  }

  static createIframe = (id: string, URL: string, CSS_STYLE: any) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("id", id);
    iframe.setAttribute("data-powered-by", "Deep Bot");
    iframe.setAttribute("frameborder", "0");

    const container = document
      .getElementById("__deepBotSdk__")
      ?.getAttribute("container");

    if (container) {
      const $container = document.querySelector(container);
      if ($container) {
        $container.appendChild(iframe);
      }
    } else {
      iframe.setAttribute("style", CSS_STYLE);
      document.body.appendChild(iframe);
    }

    iframe.setAttribute("src", URL);

    return iframe;
  };

  static removeIframe = (id: string) => {
    const iframe = document.getElementById(id);

    iframe?.remove();
  };

  get get() {
    if (!this.widget) this.createFrames();

    return this.widget;
  }

  maximize = () => {
    if (!this.widget) this.createFrames();

    this.closePrompt();

    this.widget!.style.height = "min(704px, 100% - 100px)";
    this.widget!.style.width = "416px";
    this.widget!.style.minWidth = "370px";
  };

  minimize = () => {
    if (!this.widget) this.createFrames();

    this.closePrompt();

    this.widget!.style.height = "0px";
    this.widget!.style.width = "0px";
    this.widget!.style.minWidth = "0px";
  };

  showPrompt = () => {
    this.createFrames();

    this.send({
      message: EVENTS.OPEN_PROMPT,
      event: EVENTS.OPEN_PROMPT,
      frame: this._promptFrame!,
    });

    this._promptFrame!.style.display = "flex";
    this._promptFrame!.style.height = "100px";
    this._promptFrame!.style.width = "320px";
    this._promptFrame!.style.minWidth = "220px";
  };

  closePrompt = () => {
    this.clearPromptTimeout();

    this.createFrames();

    this._promptFrame!.style.display = "none";

    this._promptFrame!.style.height = "0px";

    this._promptFrame!.style.width = "0px";

    this._promptFrame!.style.minWidth = "0px";
  };

  openImageModel = ({ message }: { message: any; type?: string }) => {
    if (!this._imageViewer) this.createFrames();

    this._imageViewer?.contentWindow?.postMessage(
      JSON.stringify({
        type: EVENTS.SEND_IMAGE_URL,
        message: message?.url,
      }),
      URL
    );

    this._imageViewer!.style.display = "flex";
  };

  closedImageModel = () => {
    if (!this._imageViewer) this.createFrames();

    this._imageViewer!.style.display = "none";
  };

  changeWidgetSize = ({ message }: { message: string; type?: string }) => {
    if (!this.widget) this.createFrames();

    this.closePrompt();

    const resizeMap = {
      [EVENTS.COLLAPSE]: this.minimize,
      [EVENTS.MAXIMIZE]: this.maximize,
    };

    const activeFunction = resizeMap[message as keyof typeof resizeMap];

    if (!activeFunction) throw new Error(`[> Invalid resize type ],${message}`);

    activeFunction?.();
  };

  get launcher() {
    return this._launcher;
  }

  get imageViewer() {
    return this._imageViewer;
  }

  send({
    message,
    frame,
    event,
  }: {
    message: any;
    frame?: HTMLIFrameElement;
    event?: string;
  }) {
    this.createFrames();

    frame?.contentWindow?.postMessage(
      JSON.stringify({ message, type: event }),
      widgetOrigin
    );
  }

  configureOrigin() {
    const frames = [
      this._imageViewer,
      this._launcher,
      this._promptFrame,
      this.widget,
    ];

    frames.filter(Boolean).forEach((frame) =>
      this.send({
        frame: frame!,
        event: EVENTS.SET_ORIGIN_URL_EVENT,
        message: window.location.origin,
      })
    );
  }

  configureTheme(theme: Api.chatWidgeData) {
    const frames = [this._imageViewer, this._launcher, this._promptFrame];

    frames.forEach((frame) =>
      this.send({
        frame: frame!,
        event: EVENTS.WIDGET_DATA,
        message: theme,
      })
    );
  }

  getConfig(knowledgeBaseId: string) {
    this.send({
      frame: this.widget!,
      event: EVENTS.GET_CONFIG,
      message: knowledgeBaseId,
    });
  }
}
