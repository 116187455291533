export function fancyConsole(message: string) {
  const styles = [
    "background: #0f0",
    "color: #000",
    "padding: 5px 10px",
    "border-radius: 5px",
  ];

  console.log(`%c ${message}`, styles.join(';'));
}


export function fancyErrorConsole(message: string) {
    
    const styles = [
      "background: #f00",
      "color: #fff",
      "padding: 5px 10px",
      "border-radius: 5px",
    ];
  
    console.log(`%c ${message}`, styles.join(';'));
  }
  

export function throttle(func:any, limit:any) {
    let inThrottle: boolean;

    return function (...args: any) {
      if (!inThrottle) {
        // @ts-ignore
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }